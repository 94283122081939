<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <div class="card card-secondary">
                    <div class="card-header">{{ formTitle }}</div>
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit="saveForm">
                            <div class="card-body">
                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <label for="name">Name *</label>
                                            <input type="text" class="form-control" id="name" placeholder="Enter your company name" v-model="formData.name" required>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="email">Email</label>
                                        <input type="email" class="form-control" id="email" placeholder="Enter your company email" v-model="formData.email">
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="phone">Phone</label>
                                        <input type="text" class="form-control" id="phone" placeholder="Enter your company email" v-model="formData.phone">
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="address">Address</label>
                                        <input type="text" class="form-control" id="address" placeholder="Enter your company address" v-model="formData.address">
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <div class="row">
                                            <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                                <label for="upload_image">Image</label>
                                                <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                            </div>
                                            <div class="col-sm-6" v-if="formData.image">
                                                <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                                <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="card-footer">
                                <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>

            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Vue from 'vue'

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('realNumber', value => {
        let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
        return regexFloatingPoint.test(value)? true: 'This field must be a number';
    });
    import {StoreService} from "@/services/store-service";
    let storeService = new StoreService();
    export default {
        name: 'Store_view',
        data: function(){
            return {
                pageName: 'Store',
                segments: [
                    { link: true, text: 'Stores', routeName: 'App.Stores' },
                    { link: false, text: 'Store' },
                ],
                upload_image: null,
                formData: {
                    id: 0,
                    name: null,
                    address: null,
                    email: null,
                    phone: null,
                    image: null,
                },
                formTitle: ''
            }
        },
        props: {},
        components: {
            BreadCrumb, ValidationObserver, ValidationProvider
        },
        computed: {

        },
        methods:{
            async loadSavedData() {
                if (this.$route.params.id !== 0) {
                    this.formData.id = this.$route.params.id;
                    let loader = this.$loading.show();
                    let response = await storeService.getStore(this.formData.id, 'edit');
                    if (response.isSuccess) {
                        this.formData = response.store;
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                        await this.$router.push({name: 'App.Stores'});
                    }
                    loader.hide();
                }
            },
            setUploadFile(){
                this.upload_image = this.$refs.upload_image.files[0];
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                storeService.formDataStore = this.formData;
                let response = await storeService.saveStore(this.upload_image);
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.$router.push({name: 'App.Stores'});
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            unsetUploadFile(){
                if(confirm("Are you sure?")) {
                    this.formData.image = null;
                }
            }
        },
        mounted() {
            this.formTitle = (this.$route.params.id === 0? 'Add ': 'Update ') + this.pageName;
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
